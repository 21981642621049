<div class="proposal">
  <div class="price-warning-info">Wszystkie wpisywane kwoty powinny być kwotami brutto</div>
  <mat-vertical-stepper linear="true" (selectionChange)="saveDraft($event);" #stepper>
    <mat-step [stepControl]="stage1">
      <form [formGroup]="stage1">
        <ng-template matStepLabel> Rejestracja do systemu dofinansowań publikacji</ng-template>
        <div class="form-group__wrapper">
          <div class="form-group__content">
            <label id="poll-label"> Wybierz pulę, w której chcesz złożyć wniosek</label>
            <mat-radio-group aria-labelledby="poll-label" class="form-group__checkbox-group" formControlName="pool">
              <ng-container *ngFor="let pool of pools|async">
                <mat-radio-button [value]="pool.id" class="checkbox"
                                  (change)="setSelectedPool(pool)"> {{pool.name}} - {{pool.id}}</mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </div>
          <div class="form-group__footer">
            <button mat-raised-button class="form-group__button" color="primary" (click)="stepperGoNext(stage1)">
              Dalej
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="stage2">
      <form [formGroup]="stage2">
        <ng-template matStepLabel> Dane wnioskodawców</ng-template>
        <div class="form-group__wrapper">
          <div class="form-group__content">
            <mat-form-field>
              <mat-label> Typ publikacji</mat-label>
              <mat-select formControlName="poolPublicationType" (ngModelChange)="selectedPublicationType($event)">
                <mat-option value="{{ type.id }}" *ngFor="let type of typesPublication"> {{ type.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="pb">
              <mat-label> Kwota dofinansowania (brutto)</mat-label>
              <input type="number" matInput placeholder=" Np. 20000" formControlName="amountFunding" (keyup)="validAmountFunding($event)">
              <mat-hint [hidden]="!maximumAmountForType" [class.error]="invalidAmountFunding"> Maksymalna kwota brutto w tej puli dla tego typu publikacji to {{ maximumAmountForType }}PLN</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label> Tytuł publikacji</mat-label>
              <input type="text" matInput placeholder="np. Pan Tadeusz" formControlName="publishTitle"  oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-form-field>
              <mat-label> ISBN</mat-label>
              <input type="text" matInput placeholder="np. A13-2012-00000035-B" formControlName="isbn" oninput="this.value = this.value.toUpperCase()">
              <mat-hint *ngIf="stage2.controls.isbn?.touched && stage2.controls.isbn?.errors" [class.error]="true">
                Podany zły numer ISBN
              </mat-hint>
            </mat-form-field>

            <mat-divider class="big-divider"></mat-divider>

            <ng-container formArrayName="publisher" *ngFor="let publisher of publisher.controls; let i=index" [formGroup]="publisher">
              <div [formGroupName]="i">
                <h2> Wydawca</h2>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Nazwa wydawcy</mat-label>
                    <input type="text" matInput placeholder="np. Amber" formControlName="name" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> E-mail</mat-label>
                    <input type="text" matInput placeholder="np. tomasz@domena.pl" formControlName="email" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="publisher.controls.email?.touched && publisher.controls.email?.errors" [class.error]="true">
                      Pole email jest obowiązkowe i musi być unikalne
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Numer telefonu</mat-label>
                    <input type="text" matInput placeholder="np. +48 555 666 777" formControlName="phone" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="publisher.controls.phone?.touched && publisher.controls.phone?.errors" [class.error]="true">
                      Podany zły numer telefonu
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Ulica, numer/mieszkanie</mat-label>
                    <input type="text" matInput placeholder="np. Kwiatowa 4a/37" formControlName="street" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Kod pocztowy</mat-label>
                    <input type="text" matInput placeholder="np. 00-001" formControlName="postcode" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Miejscowość</mat-label>
                    <input type="text" matInput placeholder="np. Warszawa" formControlName="city" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Kraj</mat-label>
                    <input type="text" matInput placeholder="np. Polska" formControlName="country" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> NIP</mat-label>
                    <input type="text" matInput placeholder="np. 725-18-01-126" formControlName="nip" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="publisher.controls?.nip?.touched && publisher.controls?.nip?.errors" [class.error]="true">
                      Podany zły numer NIP
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Numer KRS/CEIDG</mat-label>
                    <input type="text" matInput placeholder="np. 0000051233" formControlName="krsceidg" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="isVatPayer">Jestem płatnikiem VAT</mat-checkbox>
                </div>
              </div>
            </ng-container>

            <mat-divider class="big-divider"></mat-divider>

            <ng-container formArrayName="authors" *ngFor="let author of authors.controls; let i=index" [formGroup]="author">
              <div [formGroupName]="i">
                <div class="row">
                  <h2> Autor {{i+1}}</h2>
                  <button *ngIf="i > 0" mat-button color="basic" (click)="deleteAuthor(i)">
                    <mat-icon> close</mat-icon>
                  </button>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Imię</mat-label>
                    <input type="text" matInput placeholder="np. Adam" formControlName="name" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Nazwisko</mat-label>
                    <input type="text" matInput placeholder="np. Kowalski" formControlName="lastname" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Pseudonim</mat-label>
                    <input type="text" matInput placeholder="np. Richard Bachman" formControlName="nickname" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> E-mail</mat-label>
                    <input type="text" matInput placeholder="np. tomasz@domena.pl" formControlName="email" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="author.controls.email?.touched && author.controls.email?.errors" [class.error]="true">
                      Pole email jest obowiązkowe i musi być unikalne
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Numer telefonu</mat-label>
                    <input type="text" matInput placeholder="np. +48 555 666 777" formControlName="phone" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="author.controls.phone?.touched && author.controls.phone?.errors" [class.error]="true">
                      Podany zły numer telefonu
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Pesel</mat-label>
                    <input type="text" matInput placeholder="np. 0207080362" formControlName="pesel" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="author.controls.pesel?.touched && author.controls.pesel?.errors" [class.error]="true">
                      Podany zły numer PESEL
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Ulica, numer/mieszkanie</mat-label>
                    <input type="text" matInput placeholder="np. Kwiatowa 4a/37" formControlName="street" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Kod pocztowy</mat-label>
                    <input type="text" matInput placeholder="np. 00-001" formControlName="postcode" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Miejscowość</mat-label>
                    <input type="text" matInput placeholder="np. Warszawa" formControlName="city" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Kraj</mat-label>
                    <input type="text" matInput placeholder="np. Polska" formControlName="country" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>

                <mat-divider class="big-divider" *ngIf=" i+1  < stage2.get('authors')['controls'].length "></mat-divider>
              </div>
            </ng-container>
            <mat-divider class="big-divider"></mat-divider>

            <button mat-raised-button color="accent" (click)="addAuthor()">
              Dodaj kolejnego autora
            </button>
          </div>
          <div class="form-group__footer">
            <button mat-raised-button matStepperPrevious class="form-group__button">Cofnij</button>
            <button mat-raised-button class="form-group__button" color="primary" (click)="stepperGoNext(stage2)">
              Dalej
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="stage3">
      <form [formGroup]="stage3">

        <ng-template matStepLabel> Członkostwo</ng-template>
        <div class="form-group__wrapper">
          <ng-container class="form-group__content">
            <ng-container formArrayName="publisher" *ngFor="let publisher of publisherMembership.controls; let i=index" [formGroup]="publisher">
              <div [formGroupName]="i">
                <h2> Wydawca</h2>
                <div class="row">
                  <mat-checkbox formControlName="memberAssociation"> Jestem członkiem Stowarzyszenia</mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="contractConcluded"> Zawarłem ze Stowarzyszeniem umowę o zbiorowe zarządzanie</mat-checkbox>
                </div>
              </div>
            </ng-container>

            <mat-divider class="big-divider"></mat-divider>

            <ng-container formArrayName="authors" *ngFor="let author of authorsMembership.controls; let i=index" [formGroup]="author">
              <div [formGroupName]="i">
                <h2> Autor {{i+1}}</h2>
                <div class="row">
                  <mat-checkbox formControlName="memberAssociation"> Jestem członkiem Stowarzyszenia</mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="contractConcluded"> Zawarłem ze Stowarzyszeniem umowę o zbiorowe zarządzanie</mat-checkbox>
                </div>

                <mat-divider class="big-divider" *ngIf="i+1 < stage3.get('authors')['controls'].length"></mat-divider>
              </div>
            </ng-container>
          </ng-container>
          <div class="form-group__footer">
            <button mat-raised-button matStepperPrevious class="form-group__button">Cofnij</button>
            <button mat-raised-button class="form-group__button" color="primary" (click)="stepperGoNext(stage3)">
              Dalej
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="stage4">
      <form [formGroup]="stage4">
        <ng-template matStepLabel> Opis działalności</ng-template>
        <div class="form-group__wrapper">
          <div class="form-group__content">
            <div class="row">
              <mat-form-field class="example-full-width">
                <mat-label> Opis działalności twórczej wnioskodawcy - autora</mat-label>
                <textarea matInput placeholder="Maksymalnie 5000 znaków" formControlName="descriptionCreativeActivityApplicant" oninput="this.value = this.value.toUpperCase()"> </textarea>
              </mat-form-field>
            </div>

            <mat-divider class="big-divider"></mat-divider>

            <h2> Dotychczasowe publikacje wydawcy - przykładowe</h2>
            <p style="color: rgba(0, 0, 0, 0.7)">Podaj minimum 3 publikacje wydawcy</p>
            <ng-container formArrayName="publisherPublishings" *ngFor="let publishing of publisherPublishings.controls; let i=index" [formGroup]="publishing">
              <div [formGroupName]="i">
                <div class="row">
                  <h4> Publikacja {{i+1}}</h4>
                  <button *ngIf="i > 0" mat-button color="basic" (click)="deletePublisherPublishing(i)">
                    <mat-icon> close</mat-icon>
                  </button>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="reportedPlr" (change)="changeFormGroupStatusForPublisher(i)">
                    Tytuły są zgłoszone w PLR
                  </mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="reportedSubContract"  (change)="changeFormGroupStatusForPublisher(i)">
                    Tytuły są zgłoszone umowie powierzenia praw
                  </mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="reportedMembershipDeclaration">
                    Tytuły są zgłoszone w deklaracji członkostwa
                  </mat-checkbox>
                </div>

                <div class="row">
                  <mat-form-field>
                    <mat-label> Autor</mat-label>
                    <input type="text" matInput placeholder="np. Adam Kowalski" formControlName="author" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Tytuł</mat-label>
                    <input type="text" matInput placeholder="np. Pan Tadeusz" formControlName="title" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> ISBN</mat-label>
                    <input type="text" matInput placeholder="np. a13-152-23-423" formControlName="isbn" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="publishing.controls.isbn?.touched && publishing.controls.isbn?.errors" [class.error]="true">
                      Podany zły numer ISBN
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Wydawca</mat-label>
                    <input type="text" matInput placeholder="np. Amber" formControlName="publisher" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Rok wydania</mat-label>
                    <input type="text" matInput placeholder="np. 2010" formControlName="publishDate" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="example-full-width">
                    <mat-label> Rola w publikacji</mat-label>
                    <textarea matInput placeholder="Maksymalnie 5000 znaków" formControlName="roleInPublication" oninput="this.value = this.value.toUpperCase()"> </textarea>
                  </mat-form-field>
                </div>

                <mat-divider class="big-divider" *ngIf=" i+1  < stage4.get('publisherPublishings')['controls'].length "></mat-divider>
              </div>
            </ng-container>

            <div class="row">
              <button mat-raised-button color="accent" (click)="addPublisherPublishings()">
                Dodaj kolejną publikacje
              </button>
            </div>

            <mat-divider class="big-divider"></mat-divider>

            <h2> Dotychczasowe publikacje twórcy - przykładowe</h2>
            <ng-container formArrayName="authorPublishings" *ngFor="let publishing of authorPublishings.controls; let i=index" [formGroup]="publishing">
              <div [formGroupName]="i">
                <div class="row">
                  <h4> Publikacja {{i+1}}</h4>
                  <button *ngIf="i > 0" mat-button color="basic" (click)="deleteAuthorPublishing(i)">
                    <mat-icon> close</mat-icon>
                  </button>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="reportedPlr" (change)="changeFormGroupStatusForAuthor(i)">
                    Tytuły są zgłoszone w PLR
                  </mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="reportedSubContract" (change)="changeFormGroupStatusForAuthor(i)">
                    Tytuły są zgłoszone umowie powierzenia praw
                  </mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="reportedMembershipDeclaration">
                    Tytuły są zgłoszone w deklaracji członkostwa
                  </mat-checkbox>
                </div>
                <div class="row">
                  <mat-checkbox formControlName="debutantWithoutLiteraryAchievements">
                    Debiutant bez dorobku literackiego
                  </mat-checkbox>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> Autor</mat-label>
                    <input type="text" matInput placeholder="np. Adam Kowalski" formControlName="author" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Tytuł</mat-label>
                    <input type="text" matInput placeholder="np. Pan Tadeusz" formControlName="title" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field>
                    <mat-label> ISBN</mat-label>
                    <input type="text" matInput placeholder="np. a13-152-23-423" formControlName="isbn" oninput="this.value = this.value.toUpperCase()">
                    <mat-hint *ngIf="publishing.controls.isbn?.touched && publishing.controls.isbn?.errors" [class.error]="true">
                      Podany zły numer ISBN
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Wydawca</mat-label>
                    <input type="text" matInput placeholder="np. Amber" formControlName="publisher" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label> Rok wydania</mat-label>
                    <input type="text" matInput placeholder="np. 2010" formControlName="publishDate" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="example-full-width">
                    <mat-label> Rola w publikacji</mat-label>
                    <textarea matInput placeholder="Maksymalnie 5000 znaków" formControlName="roleInPublication" oninput="this.value = this.value.toUpperCase()"> </textarea>
                  </mat-form-field>
                </div>

                <mat-divider class="big-divider" *ngIf="i+1 < stage4.get('authorPublishings')['controls'].length"></mat-divider>
              </div>
            </ng-container>

            <div class="row">
              <button mat-raised-button color="accent" (click)="addAuthorPublishings()">
                Dodaj kolejną publikacje
              </button>
            </div>

            <mat-divider class="big-divider"></mat-divider>

            <h2> Opis i uzasadnienie wydania publikacji</h2>

            <div class="row">
              <mat-form-field class="example-full-width">
                <mat-label> Opis publikacji wraz ze streszczeniem</mat-label>
                <textarea matInput placeholder="Maksymalnie 5000 znaków" formControlName="descriptionPublication" oninput="this.value = this.value.toUpperCase()"> </textarea>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="example-full-width">
                <mat-label> Uzasadnienie konieczności wydania tej publikacji</mat-label>
                <textarea matInput placeholder="Maksymalnie 5000 znaków" formControlName="justificationPublishing" oninput="this.value = this.value.toUpperCase()"> </textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group__footer">
            <button mat-raised-button matStepperPrevious class="form-group__button">Cofnij</button>
            <button mat-raised-button class="form-group__button" color="primary" (click)="stepperGoNext(stage4)">
              Dalej
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="stage5">
      <ng-template matStepLabel> Kosztorys publikacji</ng-template>
      <div class="form-group__wrapper" [formGroup]="stage5">
        <div class="form-group__content">
          <!--
          <div class="row">
            <mat-form-field>
              <mat-label> Autor</mat-label>
              <input type="text" matInput placeholder="np. Adam Kowalski" formControlName="author" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-form-field>
              <mat-label> Tytuł</mat-label>
              <input type="text" matInput placeholder="np. Pan Tadeusz" formControlName="title" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          -->
          <div class="row">
            <!--
            <mat-form-field>
              <mat-label> Wydawca</mat-label>
              <input type="text" matInput placeholder="np. Amber" formControlName="publisher" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            -->
            <mat-form-field>
              <mat-label> Data wydania</mat-label>
              <input type="text" matInput placeholder="np. 2010" formControlName="publishDate" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <!--
          <div class="row">
            <mat-form-field>
              <mat-label>Kwota dofinansowania (brutto)</mat-label>
              <input type="text" matInput placeholder="np. 300 000" formControlName="amountFunding" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          -->
          <div class="row">
            <mat-form-field>
              <mat-label>Objętość w arkuszach wydawniczych</mat-label>
              <input type="text" matInput placeholder="np. 300" formControlName="publishingSheets" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Liczba stron</mat-label>
              <input type="text" matInput placeholder="np. 300" formControlName="numberPages" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Liczba ilustracji</mat-label>
              <input type="text" matInput placeholder="np. 20" formControlName="numberIllustrations" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="row">
            <!--
            <mat-form-field>
              <mat-label>ISBN</mat-label>
              <input type="text" matInput placeholder="np. ISBN" formControlName="isbnPublication" oninput="this.value = this.value.toUpperCase()">
              <mat-hint *ngIf="stage5.controls.isbnPublication?.touched && stage5.controls.isbnPublication?.errors" [class.error]="true">
                Podany zły numer ISBN
              </mat-hint>
            </mat-form-field>
            -->
            <mat-form-field>
              <mat-label>Forma książki</mat-label>
              <mat-select multiple formControlName="bookForm" (selectionChange)="setUpCosts($event)">
                <mat-option value="1"> Druk</mat-option>
                <mat-option value="2"> E-book</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-divider class="big-divider"></mat-divider>
          <div class="row">
            <mat-form-field>
              <mat-label id="prepareToPrintLabel">Przygotowanie do druku, druk</mat-label>
              <input type="text" matInput formControlName="preparationForPrinting">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Koszty przygotowania do druku (brutto)</mat-label>
              <input type="number" matInput formControlName="printingCost">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Koszty wydawcy (brutto)</mat-label>
              <input type="text" matInput formControlName="publisherCost" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Wynagrodzenie autorskie (brutto)</mat-label>
              <input type="text" matInput formControlName="copyrightFee" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Cena detaliczna</mat-label>
              <input type="text" matInput formControlName="retailPrice" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-form-field>
              <mat-label> Nakład druku</mat-label>
              <input type="text" matInput formControlName="printRun" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-checkbox formControlName="publicationFinished"> Oświadczam, że publikacja jest ukończona</mat-checkbox>
          </div>
        </div>
        <div class="form-group__footer">
          <button mat-raised-button matStepperPrevious class="form-group__button">Cofnij</button>
          <button mat-raised-button class="form-group__button" color="primary" (click)="stepperGoNext(stage5)">
            Dalej
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel> Dokumenty do wgrania</ng-template>
      <div class="form-group__wrapper">
        <div class="form-group__content">
          <div class="file-wrapper">
            <mat-card *ngFor="let file of files">
              <app-file-download [fileId]="file.id" [fileName]="file.name"></app-file-download>
            </mat-card>
          </div>
          <h4>Tutaj będzie opis jakie dokumenty należy wgrać</h4>
          <button (click)="file.click()" mat-raised-button color="primary"> Wgraj nowy dokument</button>
          <input type="file" id="file" class="hide-input" (change)="uploadFile($event.target.files)" #file>
        </div>
        <div class="form-group__footer">
          <button mat-raised-button matStepperPrevious class="form-group__button">Cofnij</button>
          <button mat-raised-button matStepperNext class="form-group__button" color="primary">Dalej</button>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="stage7">
      <ng-template matStepLabel> Oświadczenia</ng-template>
      <div class="form-group__wrapper" [formGroup]="stage7">
        <div class="form-group__content">
          <div class="row">
            <mat-checkbox formControlName="consentOne"> Wyrażam zgodę 1</mat-checkbox>
          </div>
          <div class="row">
            <mat-checkbox formControlName="consentTwo"> Wyrażam zgodę 2</mat-checkbox>
          </div>
          <div class="row">
            <mat-checkbox formControlName="consentThree"> Wyrażam zgodę 3</mat-checkbox>
          </div>
        </div>
        <div class="form-group__footer">
          <button mat-raised-button matStepperPrevious class="form-group__button"> Cofnij</button>
          <button mat-raised-button class="form-group__button" color="primary" (click)="stepperGoNext(stage7)">
            Dalej
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Podsumowanie</ng-template>
      <div class="form-group__wrapper">
        <div class="form-group__content">
          <h1>Sprawdź dane i wyślij.</h1>
          <app-proposal-summary [pools]="pools"
                                [typesPublication]="typesPublication"
                                [stage1]="stage1.getRawValue()"
                                [stage2]="stage2.getRawValue()"
                                [stage3]="stage3.getRawValue()"
                                [stage4]="stage4.getRawValue()"
                                [stage5]="stage5.getRawValue()"
                                [files]="files"
          ></app-proposal-summary>
        </div>

        <mat-divider class="big-divider"></mat-divider>
        <h1>Akcje</h1>

        <div class="form-group__footer">
          <button mat-raised-button matStepperPrevious class="form-group__button">
            Cofnij
          </button>
          <button mat-raised-button class="form-group__button" color="primary" (click)="sendProposal($event)">
            Wyślij
          </button>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
