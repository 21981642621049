import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {ProposalModel} from '../../../../models/requests/proposal/proposal.model';
import {AuthorModel} from '../../../../models/requests/author/author.model';
import {MembershipModel} from '../../../../models/requests/membership/membership.model';
import {PublisherModel} from '../../../../models/requests/publisher/publisher.model';
import {PublicationModel} from '../../../../models/requests/publication/publication.model';
import {PublicationCostModel} from '../../../../models/requests/publicationCost/publicationCost.model';
import {NipValidator} from '../../../../validators/nipValidator';
import {PeselValidator} from '../../../../validators/peselValidator';
import {IsbnValidator} from '../../../../validators/isbnValidator';
import {PhoneValidator} from '../../../../validators/phoneValidator';
import {MinThreePublisherPublicationValidator} from '../../../../validators/minThreePublisherPublicationValidator';
import {MatSelectChange} from '@angular/material/select';
import {ProposalUtils} from '../../../../services/ProposalUtils';
import {MatVerticalStepper} from '@angular/material/stepper';
import {PROPOSAL_STATUS} from '../../../../enums/proposal-status.enum';
import {AuthorPublicationModel} from '../../../../models/requests/publication/authorPublication.model';
import {UniqueAuthorEmailValidator} from '../../../../validators/uniqueAuthorEmailValidator';
import {UniquePublisherEmailValidator} from '../../../../validators/uniquePublisherEmailValidator';


@Component({
  selector: 'app-proposal-create',
  templateUrl: './proposal-create.component.html',
  styleUrls: ['./proposal-create.component.scss'],
  providers: [MinThreePublisherPublicationValidator]
})
export class ProposalCreateComponent implements OnInit {

  @ViewChild('stepper') stepper: MatVerticalStepper;

  public isLoading;
  public selectedPool = null;
  public stage1: FormGroup = this.formBuilder.group({
    pool: [null, Validators.required],
  });
  public stage2: FormGroup = this.formBuilder.group({
    poolPublicationType: [null, Validators.required],
    amountFunding: [null, Validators.required],
    publishTitle: [null, Validators.required],
    isbn: [null, [Validators.required, IsbnValidator()]],
    authors: this.formBuilder.array([]),
    publisher: this.formBuilder.array([])
  });
  public stage3: FormGroup = this.formBuilder.group({
    authors: this.formBuilder.array([]),
    publisher: this.formBuilder.array([]),
  });
  public stage4: FormGroup = this.formBuilder.group({
    authorPublishings: this.formBuilder.array([]),
    publisherPublishings: this.formBuilder.array([]),
    descriptionPublication: [null, Validators.required],
    justificationPublishing: [null, Validators.required],
    descriptionCreativeActivityApplicant: [null, Validators.required],
  }, {
    validator: [
      MinThreePublisherPublicationValidator.validatePublisher('publisherPublishings', 3),
    ]
  });
  public stage5: FormGroup = this.formBuilder.group({
    author: [null], // Validators.required
    title: [null], // Validators.required
    publisher: [null], // Validators.required
    publishDate: [null, Validators.required],
    amountFunding: [0], // Validators.required
    publishingSheets: [null, Validators.required],
    numberPages: [null, Validators.required],
    numberIllustrations: [null, Validators.required],
    isbnPublication: [null, IsbnValidator()], // Validators.required
    bookForm: [null, Validators.required],
    preparationForPrinting: [null, Validators.required],
    printingCost: [null, [Validators.min(1)]],
    publisherCost: [null, Validators.required],
    copyrightFee: [null, Validators.required],
    retailPrice: [null, Validators.required],
    printRun: [null],
    publicationFinished: [null, Validators.required],
  });
  public stage7: FormGroup = this.formBuilder.group({
    consentOne: [null, Validators.required],
    consentTwo: [null, Validators.required],
    consentThree: [null, Validators.required],
  });
  public draft;
  public fileToUpload: File = null;
  public files = [];
  public typesPublication = [];
  public maximumAmountForType = 0;
  public invalidAmountFunding = true;
  private observer: any = new Subject();
  public pools = this.observer.asObservable();

  private readonly uniqueAuthorEmailValidator: UniqueAuthorEmailValidator;
  private readonly uniquePublisherEmailValidator: UniquePublisherEmailValidator;

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    private sweetalertService: SweetalertService,
    public formBuilder: FormBuilder,
  ) {
    this.uniqueAuthorEmailValidator = new UniqueAuthorEmailValidator(apiService);
    this.uniquePublisherEmailValidator = new UniquePublisherEmailValidator(apiService);
  }

  get authors(): FormArray {
    return this.stage2.get('authors') as FormArray;
  }

  get publisher(): FormArray {
    return this.stage2.get('publisher') as FormArray;
  }

  get authorsMembership(): FormArray {
    return this.stage3.get('authors') as FormArray;
  }

  get publisherMembership(): FormArray {
    return this.stage3.get('publisher') as FormArray;
  }

  get authorPublishings(): FormArray {
    return this.stage4.get('authorPublishings') as FormArray;
  }

  get publisherPublishings(): FormArray {
    return this.stage4.get('publisherPublishings') as FormArray;
  }

  ngOnInit(): void {
    this.loadPools();
    this.pools.subscribe(data => {
      this.loadDrafts();
    });

    this.addAuthor();
    this.addPublisher();
    this.addAuthorPublishings();
    this.addPublisherPublishings();

    ProposalUtils.updateFieldsOnBookFormChange(this.stage5 as FormGroup);
  }

  public stepperGoNext(formGroup: FormGroup) {
    if (!formGroup.valid) {
      formGroup.markAllAsTouched();
      return;
    }
    this.stepper.next();
  }

  public buildAuthor(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      lastname: [null, Validators.required],
      nickname: [null],
      email: [null, [Validators.required, Validators.email], this.uniqueAuthorEmailValidator.validate],
      phone: [null, [Validators.required, PhoneValidator()]],
      pesel: [null, [Validators.required, PeselValidator()]],
      street: [null, Validators.required],
      postcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
    });
  }

  public buildPublisher(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email], this.uniquePublisherEmailValidator.validate],
      phone: [null, [Validators.required, PhoneValidator()]],
      street: [null, Validators.required],
      postcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      nip: [null, [Validators.required, NipValidator()]],
      krsceidg: [null, Validators.required],
      isVatPayer: [null],
    });
  }

  public buildAuthorMembership(): FormGroup {
    return this.formBuilder.group({
      memberAssociation: [false, Validators.required],
      contractConcluded: [false, Validators.required],
    });
  }

  public buildPublisherMembership(): FormGroup {
    return this.formBuilder.group({
      memberAssociation: [false, Validators.required],
      contractConcluded: [false, Validators.required],
    });
  }

  public buildAuthorPublishings(): FormGroup {
    return this.formBuilder.group({
      author: [null, Validators.required],
      title: [null, Validators.required],
      isbn: [null, [Validators.required, IsbnValidator()]],
      publisher: [null, Validators.required],
      publishDate: [null, [Validators.required, Validators.min(1950), Validators.max(new Date().getFullYear())]],
      roleInPublication: [null, Validators.required],
      reportedPlr: [false, Validators.required],
      reportedSubContract: [false, Validators.required],
      reportedMembershipDeclaration: [false, Validators.required],
      debutantWithoutLiteraryAchievements: [false, Validators.required],
    });
  }

  public buildPublisherPublishings(): FormGroup {
    return this.formBuilder.group({
      author: [null, Validators.required],
      title: [null, Validators.required],
      isbn: [null, [Validators.required, IsbnValidator()]],
      publisher: [null, Validators.required],
      publishDate: [null, [Validators.required, Validators.min(1950), Validators.max(new Date().getFullYear())]],
      roleInPublication: [null, Validators.required],
      reportedPlr: [false, Validators.required],
      reportedSubContract: [false, Validators.required],
      reportedMembershipDeclaration: [false, Validators.required],
    });
  }

  addAuthor(): void {
    this.addAuthorMembership();
    this.authors.push(this.buildAuthor());
  }

  addPublisher(): void {
    this.addPublisherMembership();
    this.publisher.push(this.buildPublisher());
  }

  addAuthorMembership(): void {
    this.authorsMembership.push(this.buildAuthorMembership());
  }

  addPublisherMembership(): void {
    this.publisherMembership.push(this.buildPublisherMembership());
  }

  addAuthorPublishings(): void {
    this.authorPublishings.push(this.buildAuthorPublishings());
  }

  addPublisherPublishings(): void {
    this.publisherPublishings.push(this.buildPublisherPublishings());
  }

  public deleteAuthor(i) {
    this.authors.removeAt(i);
    this.authorsMembership.removeAt(i);
  }

  public deleteAuthorPublishing(i) {
    this.authorPublishings.removeAt(i);
  }

  public deletePublisherPublishing(i) {
    this.publisherPublishings.removeAt(i);
  }

  public validAmountFunding(event) {
    if (event.target.value > this.maximumAmountForType) {
      this.invalidAmountFunding = true;
      return;
    }
    this.invalidAmountFunding = false;
  }

  public loadDrafts() {
    this.apiService.getDraft().subscribe(data => {
      /* tslint:disable */
      if (data['drafts'].length > 0) {
        this.sweetalertService.loadDraftConfirm().then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.fire('Saved!', '', 'success');
            const
              uc = c => c >= 'a' && c <= 'z'
                ? String.fromCharCode(c.charCodeAt() - 32)
                : c,
              toUpperCase = (key, value) => typeof value === 'string'
                ? Array.from(value, uc).join('')
                : value;

            this.draft = data['drafts'].slice(-1)[0];
            this.draft = JSON.parse(JSON.stringify(this.draft), toUpperCase);

            if (this.draft.fields.stage1) this.stage1.patchValue(this.draft.fields.stage1);
            if (this.draft.fields.stage2) this.stage2.patchValue(this.draft.fields.stage2);
            if (this.draft.fields.stage3) this.stage3.patchValue(this.draft.fields.stage3);
            if (this.draft.fields.stage4) this.stage4.patchValue(this.draft.fields.stage4);
            if (this.draft.fields.stage5) this.stage5.patchValue(this.draft.fields.stage5);
            if (this.draft.fields.files) this.files = this.draft.fields.files;

            this.updateAllFormGroupStatusForPublisher();
            this.updateAllFormGroupStatusForAuthor();

            this.getTypesPublicationForPool(this.stage1.controls.pool.value);
          } else {
            Swal.fire('Changes are not saved', '', 'info');
          }
        });
      }
    });
  }

  private updateAllFormGroupStatusForAuthor() {
    const formArray = this.stage4.get('authorPublishings') as FormArray;
    for (let publicationNumber = 0; publicationNumber < formArray.length; publicationNumber++) {
      const form = formArray.at(publicationNumber) as FormGroup;
      ProposalUtils.setPublishingFieldsStatus(form);
    }
  }

  public changeFormGroupStatusForAuthor(publicationNumber) {
    //this.form.controls['name'].disable();
    const formArray = this.stage4.get('authorPublishings') as FormArray;
    const form = formArray.at(publicationNumber) as FormGroup;
    ProposalUtils.setPublishingFieldsStatus(form);
  }

  private updateAllFormGroupStatusForPublisher() {
    const formArray = this.stage4.get('publisherPublishings') as FormArray;
    for (let publicationNumber = 0; publicationNumber < formArray.length; publicationNumber++) {
      const form = formArray.at(publicationNumber) as FormGroup;
      ProposalUtils.setPublishingFieldsStatus(form);
    }
  }

  public changeFormGroupStatusForPublisher(publicationNumber) {
    //this.form.controls['name'].disable();
    const formArray = this.stage4.get('publisherPublishings') as FormArray;
    const form = formArray.at(publicationNumber) as FormGroup;
    ProposalUtils.setPublishingFieldsStatus(form);
  }

  public loadPools() {
    this.isLoading = true;
    this.apiService.getAcceptedPools().subscribe((response: any) => {
      this.isLoading = false;
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public selectedPublicationType(type) {
    if (type !== null) {
      for (let i = 0; i < this.typesPublication.length; i++) {
        if (this.typesPublication[i].id == type) {
          this.maximumAmountForType = this.typesPublication[i].maximumAmountAwards;
        }
      }
    }
  }

  public getTypesPublicationForPool(pool) {
    this.isLoading = true;
    if (typeof pool === 'object' && pool !== null) {
      pool = pool.id;
    }
    this.apiService.getPoolPublicationTypesForPool(pool).subscribe((response: any) => {
      this.isLoading = false;
      this.typesPublication = response.data;
      this.maximumAmountForType = response.data.maximumAmountAwards;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public setSelectedPool(pool) {
    this.selectedPool = pool;
    console.log("selectedPool", this.selectedPool);
    this.getTypesPublicationForPool(pool);
  }

  public saveDraft($event) {

    const data = {
      stage1: this.stage1.getRawValue(),
      stage2: this.stage2.getRawValue(),
      stage3: this.stage3.getRawValue(),
      stage4: this.stage4.getRawValue(),
      stage5: this.stage5.getRawValue(),
      files: this.files,
    };
    this.apiService.saveDraft(data).subscribe(response => {
      //do nothing
    });
  }

  public uploadFile(files: FileList) {
    this.fileToUpload = files.item(0);

    this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
      console.log('postFile response', response.data);
      this.files.push(response.data);
      this.fileToUpload = null;
    }, error => {
      error.error.validationErrors
      this.errorService.handleError(error);
      this.fileToUpload = null;
    });
  }

  public sendProposal(e) {
    if (this.stage2.controls.amountFunding.value > this.maximumAmountForType) {
      this.sweetalertService.formError('Podałeś za dużą kwotę dofinansowania. Maksymalna kwota dofinansowania w tej puli dla wybranego typu publikacji to ' + this.maximumAmountForType + 'PLN');
      e.preventDefault();
      return;
    }
    const proposal: ProposalModel = {
      pool: this.stage1.controls.pool.value,
      poolPublicationType: this.stage2.controls.poolPublicationType.value,
      amountFunding: this.stage2.controls.amountFunding.value,
      publishTitle: this.stage2.controls.publishTitle.value,
      isbn: this.stage2.controls.isbn.value,
      descriptionPublication: this.stage4.controls.descriptionPublication.value,
      justificationPublishing: this.stage4.controls.justificationPublishing.value,
      status: PROPOSAL_STATUS.NEW,
      descriptionCreativeActivityApplicant: this.stage4.controls.descriptionCreativeActivityApplicant.value,
      files: this.files.map(file => file.id),
    };

    this.apiService.postProposal(proposal).subscribe((response: any) => {

      this.stage2.controls['authors'].value.forEach((item, i) => {
        const author: AuthorModel = {
          proposal: response.data.id,
          name: item.name,
          lastname: item.lastname,
          nickname: item.nickname,
          email: item.email,
          pesel: item.pesel,
          postCode: item.postcode,
          city: item.city,
          street: item.street,
          country: item.country,
          phone: item.phone
        };
        this.apiService.postAuthor(author).subscribe((authorResponse: any) => {
          const membership: MembershipModel = {
            author: authorResponse.data.id,
            publisher: 0,
            memberAssociation: this.stage3.controls.authors.value[i].memberAssociation,
            contractConcluded: this.stage3.controls.authors.value[i].contractConcluded
          };
          this.apiService.postMembership(membership).subscribe((membershipResponse: any) => {
          }, error => {
            this.sweetalertService.formError(error.error.validationErrors);
            this.errorService.handleError(error.error.validationErrors);
          });
        }, error => {
          if (error.error.validationErrors) {
            const emailErr = error.error.validationErrors.email;
            this.sweetalertService.formError(emailErr);
            this.errorService.handleError(emailErr);
          }
        });
      });

      this.stage2.controls['publisher'].value.forEach((item, i) => {
        const publisher: PublisherModel = {
          proposal: response.data.id,
          email: item.email,
          publisherName: item.name,
          postCode: item.postcode,
          city: item.city,
          street: item.street,
          country: item.country,
          phone: item.phone,
          publisherNip: item.nip,
          krsCeidg: item.krsceidg,
          isVatPayer: item.isVatPayer,
        };

        this.apiService.postPublisher(publisher).subscribe((publisherResponse: any) => {
          const membership: MembershipModel = {
            author: 0,
            publisher: publisherResponse.data.id,
            memberAssociation: this.stage3.controls.publisher.value[i].memberAssociation,
            contractConcluded: this.stage3.controls.publisher.value[i].contractConcluded
          };
          this.apiService.postMembership(membership).subscribe((membershipResponse: any) => {
          }, error => {
            this.sweetalertService.formError(error.validationErrors);
            this.errorService.handleError(error.validationErrors);
          });
        }, error => {
          this.sweetalertService.formError(error.validationErrors);
          this.errorService.handleError(error.validationErrors);
        });
      });

      this.stage4.controls['authorPublishings'].value.forEach((item, i) => {
        const publication: AuthorPublicationModel = {
          proposal: response.data.id,
          author: item.author,
          publisher: item.publisher,
          title: item.title,
          isbn: item.isbn,
          publishDate: item.publishDate,
          roleInPublication: item.roleInPublication,
          reportedPlr: item.reportedPlr,
          reportedSubContract: item.reportedSubContract,
          reportedMembershipDeclaration: item.reportedMembershipDeclaration,
          debutantWithoutLiteraryAchievements: item.debutantWithoutLiteraryAchievements
        };
        this.apiService.postAuthorPublication(publication).subscribe((authorPublicationResponse: any) => {
        }, error => {
          this.sweetalertService.formError(error.error.validationErrors);
          this.errorService.handleError(error.error.validationErrors);
        });
      });

      const publisherPublishingsValue = this.stage4.controls['publisherPublishings'].value;
      if (
        publisherPublishingsValue.length >= 3 ||
        (publisherPublishingsValue.length >= 1 && publisherPublishingsValue[0].reportedPlr === true) ||
        (publisherPublishingsValue.length >= 2 && publisherPublishingsValue[1].reportedPlr === true)
      ) {
        this.stage4.controls['publisherPublishings'].value.forEach((item, i) => {
          const publication: PublicationModel = {
            proposal: response.data.id,
            author: item.author,
            publisher: item.publisher,
            title: item.title,
            isbn: item.isbn,
            publishDate: item.publishDate,
            roleInPublication: item.roleInPublication,
            reportedPlr: item.reportedPlr,
            reportedSubContract: item.reportedSubContract,
            reportedMembershipDeclaration: item.reportedMembershipDeclaration
          };
          this.apiService.postPublisherPublication(publication).subscribe((publisherPublicationResponse: any) => {
          }, error => {
            this.errorService.handleError(error.error.validationErrors);
            this.sweetalertService.formError(error.error.validationErrors);
          });
        });
      } else {
        this.sweetalertService.formError('Masz mniej niż 3 publikacje lub nie są one ');
      }

      const stage5: PublicationCostModel = {
        proposal: response.data.id,
        author: this.stage5.controls.author.value,
        publisher: this.stage5.controls.publisher.value,
        title: this.stage5.controls.title.value,
        publishDate: this.stage5.controls.publishDate.value,
        amountFunding: this.stage5.controls.amountFunding.value,
        publishingSheets: this.stage5.controls.publishingSheets.value,
        numberPages: this.stage5.controls.numberPages.value,
        numberIllustrations: this.stage5.controls.numberIllustrations.value,
        isbnPublication: this.stage5.controls.isbnPublication.value,
        bookForm: this.stage5.controls.bookForm.value.toString(),
        preparationForPrinting: this.stage5.controls.preparationForPrinting.value,
        printingCost: this.stage5.controls.printingCost.value,
        publisherCost: this.stage5.controls.publisherCost.value,
        copyrightFee: this.stage5.controls.copyrightFee.value,
        retailPrice: this.stage5.controls.retailPrice.value,
        printRun: this.stage5.controls.printRun.value,
        publicationFinished: this.stage5.controls.publicationFinished.value,
      };

      this.apiService.postPublicationCost(stage5).subscribe((publicationCostResponse: any) => {
        Swal.fire('Pomyślnie złożono nowy wniosek!', '', 'success');
          this.apiService.getPreviewProposal(publicationCostResponse.data.id).subscribe(previewData => {
            const url = window.URL.createObjectURL(previewData);
            window.open(url, 'Download');
          }, error => {
            console.log('Error downloading the file.');
            this.errorService.handleError(error);
          });
      }, error => {
        this.sweetalertService.formError(error.error.validationErrors);
        this.errorService.handleError(error.error.validationErrors);
      });

      this.isLoading = false;
    });
  }

  setUpCosts($event: MatSelectChange) {
      if ($event.value.includes('2')) {
        document.getElementById('prepareToPrintLabel').innerText = ' Koszty przygotowania pdf-a';
      } else {
        document.getElementById('prepareToPrintLabel').innerText = ' Przygotowanie do druku, druk';
      }
      ProposalUtils.updateFieldsOnBookFormChange(this.stage5 as FormGroup);
  }
}
