import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {ApiService} from './api.service';

@Injectable()
export class ErrorService {
  constructor(
    private router: Router,
    private apiService: ApiService,
  ) { }

  private static apiMessage(error) {
    Swal.fire(
      'Błąd od API',
      error,
      'error'
    );
  }

  private static noPermissions() {
    Swal.fire(
      'Niewystarczające uprawnienia!',
      'Nie masz wystarczających uprawnień do wykonania tej akcji.',
      'error'
    );
  }

  private static serverError() {
    Swal.fire(
      'Błąd serwera',
      'Wystąpił problem z serwerem. Spróbuj ponownie później.',
      'error'
    );
  }

  public handleError(error) {
    this.apiService.checkToken();
    if (error.status !== 403 && error.status !== 500 && error.status !== 406) {
      if (error.error.detail) {
        ErrorService.apiMessage(error.error.detail);
      } else if (error.error.message) {
        ErrorService.apiMessage(error.error.message);
      } else if (typeof error.error === 'string') {
        ErrorService.apiMessage(error.error);
      }
    } else if (error.status === 403) {
      ErrorService.apiMessage(error.error.detail);
    } else {
      ErrorService.noPermissions();
    }
    if (error.status === 500) {
      ErrorService.serverError();
    }

    if (error.status === 406) {
      this.router.navigateByUrl('/change-password-expired');
    }
  }
}
