import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import * as Sentry from '@sentry/angular';

import {ApiService} from './services/api.service';
import {SweetalertService} from './services/sweetalert.service';
import {AuthService} from './services/auth.service';
import {DateService} from './services/date.service';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GuardService} from './services/guard.service';
import {AdminModule} from './pages/admin/admin.module';
import {PublisherModule} from './pages/publisher/publisher.module';
import {Router} from '@angular/router';
import {ErrorService} from './services/error.service';
import {RwdService} from './services/rwd.service';
import {SharedModule} from './shared.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AdminModule,
    PublisherModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
    },
    {
      provide: Sentry.TraceService,
        deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
        useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ApiService,
    SweetalertService,
    ErrorService,
    AuthService,
    DateService,
    GuardService,
    RwdService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
