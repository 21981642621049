<div class="register">
  <img src="assets/images/logo.jpg" alt="logo" class="register__logo">
  <mat-card class="register-card">
    <mat-card-title class="register-card__title"> Zarejestruj się!</mat-card-title>
    <mat-card-content class="register-card__content">
      <form [formGroup]="registerForm" (ngSubmit)="onFormSubmit(registerForm)">
        <mat-form-field class="register-card__input">
          <mat-label> Nazwa konta</mat-label>
          <input matInput placeholder="Np. tomek123" aria-label="username" formControlName="username">
        </mat-form-field>
        <mat-form-field class="register-card__input">
          <mat-label> Email</mat-label>
          <input matInput placeholder="Np. pat@example.com" aria-label="email" formControlName="email">
          <mat-hint *ngIf="registerForm?.controls.email?.touched && registerForm?.controls.email?.errors" [class.error]="true">
            Błędny adres email
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="register-card__input">
          <mat-label> Hasło</mat-label>
          <input matInput type="password" placeholder="Twoje hasło" aria-label="password" formControlName="password">
          <mat-hint *ngIf="registerForm?.controls.password?.touched && registerForm?.controls.password?.errors" [class.error]="true">
            Hasło powinno mieć conajmniej 8 znaków w tym minimum jedną wielką literę, jedną cyfrę i jeden znak specjalny
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="register-card__input">
          <mat-label>Powtórz hasło</mat-label>
          <input matInput type="password" placeholder="Powtórz swoje hasło" aria-label="repeat-password" formControlName="repeatPassword">
          <mat-hint *ngIf="registerForm?.controls.repeatPassword?.touched && registerForm?.controls.repeatPassword?.errors" [class.error]="true">
            Hasła różnią się od siebie
          </mat-hint>
        </mat-form-field>
        <section class="register-card__checkboxes">
          <mat-checkbox formControlName="firstCheckbox"> Zapoznałem się z informacjami o tym, jak prawidłowo wypełnić <a href="https://www.copyrightpolska.pl">wniosek</a></mat-checkbox>
          <mat-checkbox formControlName="secondCheckbox"> Akceptuję <a routerLink="/rules">regulamin</a> działalności kulturalnej SAiW CP</mat-checkbox>
          <mat-checkbox formControlName="thirdCheckbox"> Akceptuję <a routerLink="/rules-usude">regulamin</a> UŚUDE</mat-checkbox>
        </section>
        <button type="submit" mat-raised-button color="primary">Zarejestruj się</button>
      </form>
    </mat-card-content>
    <mat-card-footer class="register-card__footer">
      Masz już konto? Zaloguj się <a routerLink="/login" routerLinkActive="active">tutaj</a>.
    </mat-card-footer>
  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
