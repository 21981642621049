import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterModel} from '../../../models/requests/security/register.model';
import {ApiService} from '../../../services/api.service';
import {SweetalertService} from '../../../services/sweetalert.service';
import {Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';
import {RepeatedPasswordValidator} from '../../../validators/repetedPasswordValidator';
import {StrengthPasswordValidator} from '../../../validators/strengthPasswordValidator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [RepeatedPasswordValidator, StrengthPasswordValidator]
})
export class RegisterComponent implements OnInit {
  public isWaiting = false;
  public registerForm: FormGroup = this.formBuilder.group({
    username: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
    repeatPassword: [null, [Validators.required]],
    firstCheckbox: [null, Validators.required],
    secondCheckbox: [null, Validators.required],
    thirdCheckbox: [null, Validators.required]
  }, {
    validator: [
      StrengthPasswordValidator.isStrength('password'),
      RepeatedPasswordValidator.mustMatch('password', 'repeatPassword'),
    ]
  });

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  public onFormSubmit(registerForm: FormGroup) {
    const registerModel: RegisterModel = registerForm.value;

    this.isWaiting = true;

    if (!registerForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.registerFormError();
      return;
    }

    this.apiService.register(registerModel).subscribe(response => {
      this.isWaiting = false;
      this.sweetalertService.registerSuccess();
      this.registerForm.reset();
      this.router.navigate(['/login']);
    }, error => {
      this.isWaiting = false;
      if (error.error.validationErrors) {
        const usernameErr = error.error.validationErrors.username;
        const emailErr = error.error.validationErrors.email;
        const passwordErr = error.error.validationErrors.password;
        if (typeof usernameErr !== 'undefined') {
          if (usernameErr[0].search('not unique')) {
            this.sweetalertService.formError('Podana nazwa użytkownika jest już używana w serwisie. Użyj innej nazwy użytkownika.');
          } else {
            this.sweetalertService.formError('Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.');
          }
        }
        if (typeof emailErr !== 'undefined') {
          if (emailErr[0].search('not unique')) {
            this.sweetalertService.formError('Podany e-mail jest już używany w serwisie. Użyj innego e-maila.');
          } else {
            this.sweetalertService.formError('Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.');
          }
        }
        if (typeof passwordErr !== 'undefined') {
          this.sweetalertService.formError(passwordErr[0]);
        }
      }
    });
  }
}
