import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-proposal-summary',
  templateUrl: './proposal-summary.component.html',
  styleUrls: ['./proposal-summary.component.scss']
})
export class ProposalSummaryComponent implements OnInit {
  @Input() public stage1: any;
  @Input() public stage2: any;
  @Input() public stage3: any;
  @Input() public stage4: any;
  @Input() public stage5: any;
  @Input() public files: any[];
  @Input() public pools: Observable<any>;
  @Input() public typesPublication: any[];

  private poolNames = new Map<number, string>();

  constructor() {
  }

  ngOnInit(): void {
    this.pools.subscribe(pools => {
      pools.forEach(pool => {
        this.poolNames.set(pool.id, pool.name);
      });
    });
  }

  getPoolNameById(poolId: number): string {
    return this.poolNames.get(poolId);
  }

  getPublicationTypeNameById(publicationTypeId?: number): string {
    if (publicationTypeId !== null) {
      for (const item of this.typesPublication) {
        // tslint:disable-next-line:triple-equals
        if (item.id == publicationTypeId) {
          return item.name;
        }
      }
    }
    return publicationTypeId?.toString() || '';
  }

  displayBookForm(value: string): string {
    switch (value) {
      case '1':
        return 'Druk';
      case '2':
        return 'E-book';
      default:
        return value;
    }
  }
}
