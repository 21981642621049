import {Injectable} from "@angular/core";
import Swal from 'sweetalert2';

@Injectable()
export class SweetalertService {
  public registerSuccess() {
    Swal.fire(
      'Sukces!',
      'Pomyślnie zarejestrowano nowe konto!',
      'success'
    );
  }

  public success() {
    Swal.fire(
      'Sukces!',
      'Pomyślnie wysłano dane!',
      'success'
    );
  }

  public recoveryPasswordSuccess() {
    Swal.fire(
      'Sukces!',
      'Pomyślnie zresetowano hasło!',
      'success'
    );
  }

  public accountDeactivateSuccess() {
    Swal.fire(
      'Sukces!',
      'Twoje konto zostało dezaktywowane!',
      'success'
    );
  }

  public changePasswordSuccess() {
    Swal.fire(
      'Sukces!',
      'Zaktualizowałeś swoje hasło!',
      'success'
    );
  }

  public registerFormError() {
    Swal.fire(
      'Wystąpiły błędy w formularzu!',
      'Uzupełnij wszystkie pola!',
      'error'
    );
  }

  public loginFormError() {
    Swal.fire(
      'Nie udało się zalogować!',
      'Niepoprawny login lub hasło.',
      'error'
    );
  }

  public sessionExpired() {
    Swal.fire(
      'Sesja wygasła!',
      'Zaloguj się do serwisu ponownie.',
      'error'
    );
  }

  public accountRecoveryFormError() {
    Swal.fire(
      'Brak maila!',
      'Pole nie może zostać puste!',
      'error'
    );
  }

  public recoveryPasswordFormError() {
    Swal.fire(
      'Błędy w formularzu!',
      'Wypełnij formularz poprawnie i prześlij go ponownie!',
      'error'
    );
  }

  public formError(message: string) {
    Swal.fire(
      'Wystąpiły błędy w formularzu!',
      message,
      'error'
    );
  }

  public connectionError(error = 'Błąd ogólny') {
    Swal.fire(
      'Problem z połączeniem',
      error,
      'error'
    );
  }

  public loadDraftConfirm() {
    // @ts-ignore
   return Swal.fire({
      title: 'Czy załadować szkic?',
      showCancelButton: true,
      confirmButtonText: `Tak`,
      cancelButtonText: `Nie`,
    });
  }
}
