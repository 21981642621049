<div class="options">
  <mat-card class="options__card">
    <mat-card-title>
      Zmiana hasła
    </mat-card-title>
    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm)">
      <mat-form-field class="login-card__input">
        <mat-label> Stare hasło</mat-label>
        <input matInput type="password" aria-label="old-password" formControlName="oldPassword">
      </mat-form-field>
      <mat-form-field class="login-card__input">
        <mat-label> Nowe hasło</mat-label>
        <input matInput type="password" aria-label="password" formControlName="newPassword">
        <mat-hint *ngIf="changePasswordForm?.controls.newPassword?.touched && changePasswordForm?.controls.newPassword?.errors" [class.error]="true">
          Hasło powinno mieć conajmniej 8 znaków w tym minimum jedną wielką literę, jedną cyfrę i jeden znak specjalny
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="login-card__input">
        <mat-label> Powtórz nowe hasło</mat-label>
        <input matInput type="password" aria-label="password-repeat" formControlName="passwordRepeat">
        <mat-hint *ngIf="changePasswordForm?.controls.passwordRepeat?.touched && changePasswordForm?.controls.passwordRepeat?.errors" [class.error]="true">
          Hasła różnią się od siebie
        </mat-hint>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit"> Zapisz nowe hasło</button>
    </form>
  </mat-card>
  <mat-card class="options__card">
    <mat-card-title>
      Dezaktywuj konto
    </mat-card-title>
    <span *ngIf="!startProcess">
      Uwaga! Ten proces jest nieodwracalny. Czy na pewno chcesz to zrobić?
    </span>
    <button mat-raised-button color="warn" *ngIf="!startProcess" (click)="startProcessDeactivation()"> {{ buttonText }}</button>
    <form [formGroup]="accountDeactivationForm" (ngSubmit)="deactivateAccount(accountDeactivationForm)" *ngIf="startProcess">
      <mat-form-field class="login-card__input">
        <mat-label> Hasło</mat-label>
        <input matInput type="password" placeholder="Hasło" aria-label="password" formControlName="password">
        <mat-hint *ngIf="accountDeactivationForm?.controls.password?.touched && accountDeactivationForm?.controls.password?.errors" [class.error]="true">
          Potwierdzenie hasłem jest wymagane do deaktywacji konta
        </mat-hint>
      </mat-form-field>
      <button mat-raised-button color="warn" type="submit"> {{ buttonText }}</button>
    </form>
  </mat-card>
</div>
