import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginModel} from '../../../models/requests/security/login.model';
import {AccountDeactivationModel} from '../../../models/requests/account/account-deactivation.model';
import {SweetalertService} from '../../../services/sweetalert.service';
import {ApiService} from '../../../services/api.service';
import {ErrorService} from '../../../services/error.service';
import {Router} from '@angular/router';
import {ChangePasswordModel} from '../../../models/requests/account/change-password.model';
import {RepeatedPasswordValidator} from '../../../validators/repetedPasswordValidator';
import {StrengthPasswordValidator} from '../../../validators/strengthPasswordValidator';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
  providers: [RepeatedPasswordValidator, StrengthPasswordValidator]
})
export class OptionsComponent implements OnInit {

  public startProcess = false;
  public buttonText = 'Dezaktywuj konto na stałe';

  public isWaiting = false;
  public changePasswordForm: FormGroup = this.formBuilder.group({
    oldPassword: [null, Validators.required],
    newPassword: [null, [Validators.required]],
    passwordRepeat: [null, Validators.required],
  }, {
    validator: [
      StrengthPasswordValidator.isStrength('newPassword'),
      RepeatedPasswordValidator.mustMatch('newPassword', 'passwordRepeat'),
    ]
  });
  public accountDeactivationForm: FormGroup = this.formBuilder.group({
    password: [null, Validators.required],
  });

  constructor(
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public changePassword(changePasswordForm) {
    const changePasswordModel: ChangePasswordModel = changePasswordForm.value;

    if (!changePasswordForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.registerFormError();
      return;
    }

    this.isWaiting = true;

    this.apiService.changePassword(changePasswordModel).subscribe((response: any) => {
      this.isWaiting = false;
      if (response.status === 200) {
        this.sweetalertService.changePasswordSuccess();
        changePasswordForm.reset();
        Object.keys(changePasswordForm.controls).forEach(key => {
          changePasswordForm.controls[key].setErrors(null);
        });
      }
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
      const passwordErr = error.error.validationErrors.newPassword;
      if (typeof passwordErr !== 'undefined') {
        this.sweetalertService.formError(passwordErr[0]);
      }
    });
  }

  public startProcessDeactivation() {
    this.startProcess = true;
    this.buttonText = 'Potwierdzam dezaktywację konta';
  }

  public deactivateAccount(accountDeactivationForm) {
    const accountDeactivationModel: AccountDeactivationModel = accountDeactivationForm.value;

    if (!accountDeactivationForm.valid) {
      this.sweetalertService.registerFormError();
      return;
    }

    this.isWaiting = true;

    this.apiService.accountDeactivation(accountDeactivationModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.startProcess = false;
      this.buttonText = 'Dezaktywuj konto na stałe';
      if (response.status === 200) {
        localStorage.removeItem('token');
        this.router.navigate(['/']);
        this.sweetalertService.accountDeactivateSuccess();
      }
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }
}
